import React from 'react';
import { Button } from 'radiance-ui';

import PAGE_URLS from 'consts/pageUrls';
import { desktopOnly, mobileOnly } from 'styles/mixins/displayMobileDesktop';
import { navInternalWithQueryString } from 'utils/nav';

import backgroundDesktop from './images/backgroundDesktop.jpg';
import backgroundDesktop2x from './images/backgroundDesktop@2x.jpg';
import backgroundMobile from './images/backgroundMobile.jpg';
import backgroundMobile2x from './images/backgroundMobile@2x.jpg';
import {
  ContentBox,
  descriptionStyles,
  imgStyles,
  InnerContainer,
  OuterContainer,
} from './style';

const NotFound = () => {
  const onClick = () => {
    navInternalWithQueryString(PAGE_URLS.landingPage());
  };

  return (
    <OuterContainer>
      <InnerContainer>
        <ContentBox>
          <h2>
            Sorry! We mixed
            <br />
            things up.
          </h2>
          <p css={descriptionStyles}>
            The link you clicked may be broken or the page may have been
            removed.
          </p>
          <Button onClick={onClick}>Go to Home</Button>
        </ContentBox>
        <div>
          <img
            css={[desktopOnly(), imgStyles]}
            srcSet={`${backgroundDesktop2x} 2x`}
            src={backgroundDesktop}
            alt="Future formula bottle on stack of papers"
          />
          <img
            css={[mobileOnly(), imgStyles]}
            srcSet={`${backgroundMobile2x} 2x`}
            src={backgroundMobile}
            alt="Future formula bottle on stack of papers"
          />
        </div>
      </InnerContainer>
    </OuterContainer>
  );
};

export default NotFound;
