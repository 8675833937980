import React from 'react';

import Nav, { NavPropsType } from 'components/global/nav';
import SEO, { SEOPropsType } from 'components/global/seo';
import Footer from 'components/global/footer';

const Layout = ({
  children,
  nav,
  seo,
}: {
  children: React.ReactNode;
  nav?: NavPropsType;
  seo: SEOPropsType;
}) => {
  return (
    <React.Fragment>
      <SEO {...seo} />
      <Nav {...nav} />
      <main>{children}</main>
      <Footer />
    </React.Fragment>
  );
};

export default Layout;
