import React from 'react';

import NotFound from 'components/global/errors/notFound';
import Layout from 'components/global/layout';

const NotFoundPage = () => {
  return (
    <Layout seo={{ title: 'Agency - Not Found' }}>
      <NotFound />
    </Layout>
  );
};

export default NotFoundPage;
