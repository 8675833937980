import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { SPACER, MEDIA_QUERIES } from 'radiance-ui/lib/constants';

export const ContentBox = styled.div`
  padding: 4.5rem 0 ${SPACER.x2large};

  ${MEDIA_QUERIES.lgUp} {
    align-self: flex-start;
    padding: 11rem 0 14rem;
  }
`;

export const descriptionStyles = css`
  margin: ${SPACER.large} 0;
  max-width: 23rem;

  ${MEDIA_QUERIES.lgUp} {
    margin: ${SPACER.xlarge} 0;
  }
`;

export const imgStyles = css`
  width: 80vw;
  max-width: 430px;
  height: auto;

  ${MEDIA_QUERIES.lgUp} {
    width: 41.4vw;
    max-width: 550px;
  }
`;

export const InnerContainer = styled.div`
  padding: 0 ${SPACER.large};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: hidden;
  height: calc(100vh - 64px);
  align-items: center;
  min-height: 685px;

  ${MEDIA_QUERIES.lgUp} {
    flex-direction: row;
    align-items: flex-end;
    max-width: 1280px;
    margin: auto;
    padding: 0 4.5rem;
    height: calc(100vh - 80px);
  }
`;

export const OuterContainer = styled.div`
  background-color: rgb(212, 208, 207);
`;
